import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './form/input/text-input/text-input.component';
import { RichTextEditorComponent } from './form/input/rich-text-editor/rich-text-editor.component';



@NgModule({
  declarations: [
    TextInputComponent,
    RichTextEditorComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule
  ],
  exports: [
    TextInputComponent,
    RichTextEditorComponent
  ]
})
export class CommonLibModule { }
