<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">{{ label }}</label>

    <div class="relative">
      <div *ngIf="iconComponent" class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <ng-content></ng-content>
      </div>
      <input
        type="text"
        [ngClass]="inputClasses"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="isDisabled"
        (input)="onInputChange($event)"
      />
    </div>
    <ng-container *ngIf="submitted">
        <span *ngFor="let errorMessage of getActiveErrorMessages()" class="block mt-2 text-error-500">{{ errorMessage }}</span>
    </ng-container>
  </div>